<template>
  <b-card class="dashboard-card shadow-sm">
    <section>
      <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'">
        <h1 class="bold-header-text app-header mb-3">
          <span :title="tooltipText">E-News Sign Up </span>
        </h1>
        <p>
          Stay in the know by selecting your preferred ACHC e-newsletter(s) for delivery direct to
          your inbox.
        </p>
        <template v-slot:myTooltipTemplate="{ props }">
          <a>
            <strong>{{ props.title }}</strong>
          </a>
        </template>
      </Tooltip>
      <b-row class="mb-2">
        <b-col md="12">
          <form id="LoginForm"></form>
        </b-col>
      </b-row>
    </section>
  </b-card>
</template>
<style lang="scss" scoped></style>
<script>
import { Tooltip } from "@progress/kendo-vue-tooltip";
import { bus } from "../../../main";
export default {
  inject: ["tabsWithTooltip"],
  components: {
    Tooltip,
  },
  data() {
    return {
      tooltipText: "",
    };
  },
  created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });
    this.tooltipText = this.tabsWithTooltip.find(({ to }) => to === "EnewsSignup")?.tooltip || ""; // injected value

    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "5234004",
          formId: "a5ac39d8-5e13-4ebe-aa88-57c1e92509be",
          target: "#LoginForm",
        });
      }
    });
    setTimeout(() => {
      bus.$emit("showFullpageOverlay", {
        showLayover: false,
      });
    }, 1000);
  },
};
</script>
